import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import DropDownProjectFilter from './Project/DropdownProjectFilter/DropDownProjectFilter';
import ProjectFilter from './Project/ProjectFilter/ProjectFilter';
import '../../assets/css/portfolio.css';
import ProjectGrid from './Project/ProjectGrid/ProjectGrid';
import HeadingBanner from '../Shared/HeadingBanner/HeadingBanner';

// imported it for using carousel
const $ = typeof window !== `undefined` ? require('jquery') : null;

const SOLUTIONS = [
  {value: 'Web Development', label: 'Web Development'},
  {value: 'Product Design', label: 'Product Design'},
  {value: 'Quality Assurance', label: 'Quality Assurance'},
  {value: 'Mobile Applications', label: 'Mobile Applications'},
  {value: 'User Experience', label: 'User Experience'},
  {value: 'User Interface', label: 'User Interface'},
  {value: 'Staff Augmentation', label: 'Staff Augmentation'},
  {value: 'Game Design', label: 'Game Design'},
  {value: 'Game Development', label: 'Game Development'},
  {value: 'Project Management', label: 'Project Management'},
  {value: 'IoT Development', label: 'IoT Development'},
  {value: 'Cloud Services', label: 'Cloud Services'},
  {value: 'MVP Development', label: 'MVP Development'},
  {value: 'Art and Animations', label: 'Art and Animations'},
  {
    value: 'Extensions and Integrations',
    label: 'Extensions and Integrations',
  },
];

const INDUSTRIES = [
  {value: 'Agriculture', label: 'Agriculture'},
  {value: 'Healthcare & Life Sciences', label: 'Healthcare & Life Sciences'},
  {value: 'Manufacturing', label: 'Manufacturing'},
  {value: 'Financial Services', label: 'Financial Services'},
  {value: 'Lifestyle', label: 'Lifestyle'},
  {value: 'Education & eLearning', label: 'Education & eLearning'},
  {value: 'Media & Entertainment', label: 'Media & Entertainment'},
  {value: 'E-Sports & Gaming', label: 'E-Sports & Gaming'},
  {value: 'Retail & eCommerce', label: 'Retail & eCommerce'},
  {value: 'News & Social Applications', label: 'News & Social Applications'},
  {value: 'Photo Editing and Printing', label: 'Photo Editing and Printing'},
  {value: 'Real Estate', label: 'Real Estate'},
  {
    value: 'Workplace Experience & Management',
    label: 'Workplace Experience & Management',
  },
];

const TECHNOLOGIES = [
  {
    label: 'Web',
    options: [
      {value: 'Python', label: 'Python'},
      {value: 'Ruby on Rails', label: 'Ruby on Rails'},
      {value: 'NodeJS', label: 'NodeJS'},
      {value: 'ReactJS', label: 'ReactJS'},
      {value: 'AngularJS', label: 'AngularJS'},
      {value: 'Java', label: 'Java'},
      {value: 'Django', label: 'Django'},
    ],
  },
  {
    label: 'Apps',
    options: [
      {value: 'iOS (Swift)', label: 'iOS (Swift)'},
      {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
      {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
      {value: 'React Native', label: 'React Native'},
      {value: 'Flutter', label: 'Flutter'},
    ],
  },
  {
    label: 'Game',
    options: [
      {value: 'Unity', label: 'Unity'},
      {value: 'Construct 2', label: 'Construct 2'},
      {value: 'Cocos2d-x', label: 'Cocos2d-x'},
      {value: 'Phaser', label: 'Phaser'},
    ],
  },
  {
    label: 'IoT',
    options: [
      {value: 'IoT Software Development', label: 'IoT Software Development'},
      {value: 'Iot Hardware Development', label: 'Iot Hardware Development'},
    ],
  },
  {
    label: 'Cloud Services',
    options: [
      {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      {value: 'Google Cloud Platform', label: 'Google Cloud Platform'},
      {
        value: 'Google Application Engine',
        label: 'Google Application Engine',
      },
      {
        value: 'Firebase Realtime Database',
        label: 'Firebase Realtime Database',
      },
      {value: 'Heroku', label: 'Heroku'},
      {value: 'BigQuery', label: 'BigQuery'},
      {value: 'Jenkins', label: 'Jenkins'},
      {value: 'Cloud Firestore Database', label: 'Cloud Firestore Database'},
    ],
  },
  {
    label: 'Augmented Reality',
    options: [
      {value: 'ARKit', label: 'ARKit'},
      // { value: "ARCore", label: "ARCore" },
    ],
  },
];

// All Categories
const CATEGORIES = {
  solutions: SOLUTIONS,
  industries: INDUSTRIES,
  technologies: TECHNOLOGIES,
};
// All Projects
const PROJECTS = [
  {
    id: 'tpl',
    featuredImageSrc: 'the-player-lounge.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/the-player-lounge.png').default,
    projectDescription:
      'TPL is a fan engagement platform for US collegiate athletics. We helped them launch an MVP in 2 months and raise $2.5M in funding.',
    projectName: 'tpl',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/thePlayerLounge/',
  },
  {
    id: 'design pro',
    featuredImageSrc: 'design-pro.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/design-pro.png').default,
    projectDescription:
      'We leveraged AI in this Figma plugin to transform comments, notes, docs, and video call transcripts to tasks instantly and help streamline design workflows.',
    projectName: 'design pro',
    category: ['AI'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/design-pro/',
  },
  {
    id: 'decorist',
    featuredImageSrc: 'decorist.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/decorist.svg').default,
    projectDescription:
      'Decorist is an interior design app for photorealistic rendering. We decreased the time for designing a room and delivering a high-quality render from 2 weeks to 2 hours.',
    projectName: 'decorist',
    category: ['Web', 'AR/VR'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/decorist-room-builder/',
  },
  {
    id: 'Fortune500 AR',
    featuredImageSrc: 'bed-bath-beyond.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/careem.svg').default,
    projectDescription:
      'We completed the AR transformation of a Fortune 500 company’s e-commerce mobile application.',
    projectName: 'bed-bath-beyond',
    category: ['Web', 'AR/VR', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/fortune500-AR-transformation/',
  },
  {
    id: 'Cryptocurrency Exchange',
    featuredImageSrc: 'cryptocurrency-exchange.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/careem.svg').default,
    projectDescription:
      'We delivered Rosetta implementations for a leading cryptocurrency exchange platform.',
    projectName: 'Allergy-Amulet',
    category: ['Web 3.0'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/cryptocurrency-exchange-platform/',
  },
  {
    id: 'life-print',
    featuredImageSrc: 'life-print.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/lifeprint.svg').default,
    projectDescription:
      'We helped them convert their idea into a working POC, raise multiple rounds of funding, take the product global, and become an Apple retail partner.',
    projectName: 'life-print',
    category: ['Web', 'IoT', 'Apps'],
    categories: {
      solutions: [
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/lifeprint',
  },
  {
    id: 'Food Sensor',
    featuredImageSrc: 'Food-Sensor.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/careem.svg').default,
    projectDescription:
      'We designed a sleek and lightweight product in 4 months that could deliver test results for the 8 most common food allergens within 60 seconds.',
    projectName: 'Allergy-Amulet',
    category: ['Apps', 'HealthTech', 'IoT'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/food-allergen-sensor/',
  },

  {
    id: 'gamerize',
    featuredImageSrc: 'gamerize.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/gamerize.svg').default,
    projectDescription:
      'We built an educational game for Gamerize with a series of language learning mini-games embedded within a larger city-building game yielding 2000 organic users to the app, 500 daily sessions and 10 check-ins per day.',
    projectName: 'gamerize',
    category: ['Games', 'EdTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/gamerize/',
  },
  {
    id: 'sesame',
    featuredImageSrc: 'sesame.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/sesame.svg').default,
    projectDescription:
      "We reviewed the legacy technology for some of Sesame's most loved titles, and updated them for best performance and best practices on the latest devices.",
    projectName: 'sesame',
    category: ['Games', 'EdTech'],
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/sesame/',
  },

  {
    id: 'jobbox',
    featuredImageSrc: 'jobbox.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/jobbox.svg').default,
    projectDescription:
      'We developed an MVP for iOS and Android within three months, allowing JobBox to expand coverage to newer service areas and successfully raise seed funding.',
    projectName: 'jobobx',
    category: ['Apps'],
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/jobbox/',
  },

  {
    id: 'bbqguru',
    featuredImageSrc: 'bbqguru.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/bbqguru.svg').default,
    projectDescription:
      'We converted BBQ Guru’s apps from React Native to Native apps and re-wrote the firmware component for the hardware to enhance communication between the app and the device.',
    projectName: 'bbqguru',
    category: ['Web', 'IoT', 'Apps'],
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/bbqguru/',
  },

  {
    id: 'Fasset',
    featuredImageSrc: 'Fasset.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/fasset.svg').default,
    projectDescription:
      "We switched from Binance to a more flexible backend service provider and integrated use cases to enhance the functionality of the client's crypto services.",
    projectName: 'Fasset',
    category: ['Web 3.0', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/finance-app/',
  },
  {
    id: 'Swvl',
    featuredImageSrc: 'swvl.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/swvl.svg').default,
    projectDescription:
      'Swvl is a global provider of transformative tech-enabled mass transit solutions and ridesharing services. Tintash partnered with them for multiple process improvements for their global expansion.',
    projectName: 'Swvl',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/swvl/',
  },
  {
    id: 'raven',
    featuredImageSrc: 'raven.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/raven.svg').default,
    projectDescription:
      "Raven is a US-based platform for real estate agents that wants to replace the big brokerages taking huge money off the agents' leads and commissions.",
    projectName: 'raven',
    category: ['Web'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/raven/',
  },

  {
    id: 'Breast Cancer Suvivor App',
    featuredImageSrc: 'breast-cancer-suvivor-app.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/brilliantly-warm.png').default,
    projectDescription:
      'Brilliantly Warm is an app-controlled warming wearable that we developed to help mastectomy patients from feeling cold and uncomfortable.',
    projectName: 'Brilliantly-Warm',
    category: ['Apps', 'IoT', 'HealthTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/brilliantly-warm',
  },
  {
    id: 'Beat Bugs',
    featuredImageSrc: 'Beat-Bugs.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/beatbugs.svg').default,
    projectDescription:
      "Tintash developed a premium interactive app showcasing scenes based on The Beatles' famous songs from the Emmy award-winning Beat Bugs series.",
    projectName: 'Beat-Bugs',
    category: ['Games', 'EdTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/beatbugs',
  },
  {
    id: 'audio-cardio',
    featuredImageSrc: 'audio-cardio.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/audio-cardio.svg').default,
    projectDescription:
      "AudioCardio™ is a data and science-backed sound therapy designed to maintain and strengthen your hearing. It's like physical therapy for your hearing.",
    projectName: 'audio-cardio',
    category: ['Web', 'HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,

    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/audio-cardio',
  },
  {
    id: 'Genopets',
    featuredImageSrc: 'genopets-banner.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/genopets.png').default,
    projectDescription:
      "Developed the world's first move-to-earn NFT game that organically grew to over 80k active players in less than six months, with a D30 retention averaging a staggering 57% (25x the industry average).",
    projectName: 'audio-cardio',
    category: ['Web 3.0', 'Apps', 'Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,

    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/genopets',
  },
  {
    id: 'alexgo',
    featuredImageSrc: 'alexgo.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/alexgo.svg').default,
    projectDescription:
      'ALEX is an open-source DeFi development company built on the Stacks Blockchain and modeled on the world’s financial markets.',
    projectName: 'raven',
    category: ['Web 3.0'],
    categories: {
      solutions: [
        {value: 'Frontend', label: 'Frontend'},
        {value: 'Smart Contracts', label: 'Smart Contracts'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/alexgo',
  },
  {
    id: 'OmniActive',
    featuredImageSrc: 'OmniActive.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/omni-active.svg').default,
    projectDescription:
      'Omni Active provides clients with online, virtual, and in-person workout programs. Tintash developed the industry changing platform that digitally transformed and helped them grow.',
    projectName: 'OmniActive',
    category: ['Web'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: true,
    caseStudyUrl: '/portfolio/omni-fitness',
  },
  {
    id: 'Nail Salon 3D',
    featuredImageSrc: 'nail-salon-3d-image.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/nail-salon-3d.svg').default,
    projectDescription:
      '#4 in Top Free Games, USA AppStore with 50 million worldwide downloads. Nail salon game gives you the chance to become perfect your manicure madness and become the best Nail Salon.',
    projectName: 'Nail-Salon-3D',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Food Cutting ',
    featuredImageSrc: 'food-cutting-image.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/food-cutting.svg').default,
    projectDescription:
      '#3 in Top Free Games USA AppStore.. Swipe or cut food pieces according to weight and make customers happy.',
    projectName: 'Food-Cutting',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'Careem',
    featuredImageSrc: 'careem.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/careem.svg').default,
    projectDescription:
      'Careem is a superapp for everyday needs. Tintash re-architected the Careem Bus project, using the MVVM pattern by providing team augmentation.',
    projectName: 'Careem',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'stacks',
    featuredImageSrc: 'stacks.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/stacks.svg').default,
    projectDescription:
      'Stacks is an open-source network of decentralized apps and smart contracts built on Bitcoin. ',
    projectName: 'raven',
    category: ['Web 3.0'],
    categories: {
      solutions: [
        {value: 'Team Augmentation ', label: 'Team Augmentation'},
        {value: 'Rosetta Implementation ', label: 'Rosetta Implementation'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'All3d',
    featuredImageSrc: 'all3d.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/all3d.svg').default,
    projectDescription:
      'A platform for creating and delivering 3D Models & Services to provide immersive visual experiences for online shopping and personalized services.',
    projectName: 'All3d',
    category: ['Web', 'AI', 'AR/VR'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'draft-kings',
    featuredImageSrc: 'draft-kings.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/draft-kings.svg').default,
    projectDescription:
      'A Juggernaut in fantasy sports, DraftKings worked with Tintash on their mobile applications for fantasy sports competitions and sports news.',
    projectName: 'draft-kings',
    category: ['Apps'],
    isLogoRound: true,
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'MVP Development', label: 'MVP Development'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'embr',
    featuredImageSrc: 'embr.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/embr.svg').default,
    projectDescription:
      'Embr Labs’ heating and cooling bracelet that lets you take control & make yourself comfortable, anytime, anywhere through the connected application.',
    projectName: 'embr',
    category: ['IoT', 'HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'IoT Development', label: 'IoT Development'},
        {value: 'Cloud Services', label: 'Cloud Services'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'care-karts',
    featuredImageSrc: 'care-karts.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/care-karts.svg').default,
    projectDescription:
      'This side scrolling racer starring the Care Bears characters is the ultimate Build it, Fuel it, Chase it adventure!',
    projectName: 'care-karts',
    category: ['Games'],
    isLogoRound: true,
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Game Design', label: 'Game Design'},
        {value: 'Game Development', label: 'Game Development'},
        {value: 'Art and Animations', label: 'Art and Animations'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'little-pony',
    featuredImageSrc: 'little-pony.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/little-pony.svg').default,
    projectDescription:
      'My Little Pony lets you choose your favorite characters, props, and locations in Equestria and then add your own voice to narrate the tale.',
    projectName: 'little-pony',
    category: ['Games'],
    isLogoRound: true,
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Game Development', label: 'Game Development'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'stick-sports',
    featuredImageSrc: 'stick-sports.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/stick-sports.svg').default,
    projectDescription:
      'World’s most popular cricket game, easy to play yet hard to master. It is you versus the world. One by one knock over the teams on your way to glory!',
    projectName: 'stick-sports',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Game Development', label: 'Game Development'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'camaradly',
    featuredImageSrc: 'camaradly.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/camaradly.webp').default,
    projectDescription:
      'Camaradly is an employee-first feedback, engagement, and growth platform promoting regular feedback, ensuring a complete, and visible workplace environment.',
    projectName: 'camaradly',
    category: ['Web'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Project Management', label: 'Project Management'},
        {value: 'MVP Development', label: 'MVP Development'},
      ],
      industries: [
        {
          value: 'Workplace Experience & Management',
          label: 'Workplace Experience & Management',
        },
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'novi',
    featuredImageSrc: 'novi.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/novi.webp').default,
    projectDescription:
      'NOVI is a game of visual intelligence. It’s new, modern artistic look and feel with black & white tile designs provide hours of escape and addictive fun.',
    projectName: 'novi',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Game Design', label: 'Game Design'},
        {value: 'Game Development', label: 'Game Development'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'acciyo',
    featuredImageSrc: 'acciyo.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/acciyo.webp').default,
    projectDescription:
      'Acciyo allows you to open any story, at any time, with all the context you need to feel like you’ve been following along the whole time.',
    projectName: 'acciyo',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'MVP Development', label: 'MVP Development'},
        {
          value: 'Extensions and Integrations',
          label: 'Extensions and Integrations',
        },
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'ander',
    featuredImageSrc: 'ander.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/ander.webp').default,
    projectDescription:
      'Ander is the first platform to unify life and work in a rewarding, playful, and personalized experience that keeps everyone truly engaged.',
    projectName: 'ander',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Experience', label: 'User Experience'},
        {value: 'User Interface', label: 'User Interface'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'charadicon',
    featuredImageSrc: 'charadicon.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/charadicon.webp').default,
    projectDescription:
      "Experience a new way to play charades. Players pair up in a two person team in a texting-style guessing game using 100's of unique and groovy emoticons.",
    projectName: 'charadicon',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Game Development', label: 'Game Development'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'SyncThink',
    featuredImageSrc: 'SyncThink.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/syncthink.svg').default,
    projectDescription:
      'SyncThink’s featured product, EYE-SYNC, works with eye-target synchronization metrics to help clinicians evaluate a key impairment in head injury.',
    projectName: 'SyncThink',
    category: ['HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Sentinel Healthcare',
    featuredImageSrc: 'Sentinel-Healthcare.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/alertive.svg').default,
    projectDescription:
      'Alertive provides remote patient monitoring solutions to help systems and patients. Tintash developed an Android app for Alertive.',
    projectName: 'Sentinel-Healthcare',
    category: ['HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Ardha',
    featuredImageSrc: 'Ardha.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/ardha.svg').default,
    projectDescription:
      'Ardha is HIPAA-compliant communication platform used by healthcare professionals. Tintash developed their iOS and Android apps.',
    projectName: 'Ardha',
    category: ['HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'PillDrill',
    featuredImageSrc: 'PillDrill.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/pilldrill.svg').default,
    projectDescription:
      "PillDrill is an easy-to-use medication tracking system. Tintash created native iOS and Android apps that seamlessly interfaced with PillDrill's hardware to Bluetooth communication.",
    projectName: 'PillDrill',
    category: ['HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Nitrocam',
    featuredImageSrc: 'Nitrocam.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/nitrocam.svg').default,
    projectDescription:
      'Tintash developed a complete video security solution with custom camera, streaming infrastructure, mobile apps, and computer vision.',
    projectName: 'Nitrocam',
    category: ['IoT'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'OculAR',
    featuredImageSrc: 'Ocular.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/oculAr.svg').default,
    projectDescription:
      'Ocular Technologies provides an integrated ophthalmic telemedical solution that improves access to eye doctors. Tintash provided expertise to Ocular for web application development and firmware integration.',
    projectName: 'OculAR',
    category: ['IoT', 'HealthTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'Alkanza',
    featuredImageSrc: 'Alkanza.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/alkanza.svg').default,
    projectDescription:
      'Alkanza is a fintech investment application. The client was looking for iOS, Android and Web frontend applications for data prediction and visualisation.',
    projectName: 'Alkanza',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Abound',
    featuredImageSrc: 'Abound.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/abound.svg').default,
    projectDescription:
      'Abound parenting empowers parents to use easy, everyday moments to build the skills children need to think critically, and read well. Tintash developed developed their iOS and Android apps.',
    projectName: 'Abound',
    category: ['EdTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Health-chatbot',
    featuredImageSrc: 'Health-chatbot.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/careem.svg').default,
    projectDescription:
      'An AI-powered clinical chat assistant that provides easy access to comprehensive hospital information.',
    projectName: 'BotMD',
    category: ['HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Connectera',
    featuredImageSrc: 'Connectera.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/connectera.svg').default,
    projectDescription:
      'A smart farming solution which helps to run the farms efficiently by working with farmers to identify issues, recommend solutions, and streamline operations.',
    projectName: 'Connectera',
    category: ['Web', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Metrasense',
    featuredImageSrc: 'Metrasense.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/metrasense.svg').default,
    projectDescription:
      'Metrasens is the expert and global leader in the development and application of advanced magnetic detection technologies in healthcare and security markets.',
    projectName: 'Metrasens',
    category: ['IoT', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'IEHP',
    featuredImageSrc: 'IEHP.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/IEHP-logo.webp').default,
    projectDescription:
      'IEHP’s app member benefits include changing primary physician, checking referral status, prescriptions, claims history and lab history. We developed their Native iOS and Android apps to interface with APIs built by the client.',
    projectName: 'IEHP',
    category: ['HealthTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Gulp',
    featuredImageSrc: 'gulp.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/gulp.svg').default,
    projectDescription:
      'A 3D hyper casual game where you roam around the city absorbing everything in sight. The client wanted to make an addictive AI based hyper-casual game monetized by ads and in-app purchases.',
    projectName: 'Gulp',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Ball Jam',
    featuredImageSrc: 'BallJam.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/balljam.svg').default,
    projectDescription:
      'Tintash created the Basketball shooting hyper-casual game from scratch, providing Game design, Unity Development, Art, QA and Project Management services to the client.',
    projectName: 'Ball-Jam',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: "Haiku's Adventure Escape: Haunted Mansion",
    featuredImageSrc: 'Haunted-Hunt.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/haiku-haunted-hunt.svg').default,
    projectDescription:
      "Haiku's Adventure Escape™ series has been played by tens of millions of players. Tintash provided game development services and worked on their iOS and Android versions of the game.",
    projectName: 'Haiku-Haunted-Mansion',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: "Haiku's Adventure Escape: Space Crisis",
    featuredImageSrc: 'Haiku-Space.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/haiku-space.svg').default,
    projectDescription:
      "Haiku's Adventure Escape™ series has been played by tens of millions of players. Tintash provided game development services and worked on their iOS and Android versions of the game.",
    projectName: 'Haiku-Space-Crisis',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Transformers Rescue Bots',
    featuredImageSrc: 'Rescue-Bots.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/rescue-bots.svg').default,
    projectDescription:
      'The client had previously built storybook games on flash and wanted to add mini games alongside the storybook experience. Tintash ported Flash-based storybook games to Unity, enhancing the interactive experience.',
    projectName: 'Transformers-Rescue-Bots.webp',
    category: ['Games'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Math Wizard',
    featuredImageSrc: 'Math-Wizard.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/math-wizard.svg').default,
    projectDescription:
      'Wizard Math is a light RPG (Role Playing Game) for 1st graders. Tintash developed puzzles and maps for this RPG, where players explore a magical world and fight monsters.',
    projectName: 'Math-Wizard.webp',
    category: ['Games', 'EdTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Kooledge',
    featuredImageSrc: 'Kooledge.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/kooledge.svg').default,
    projectDescription:
      'An online learning and teaching platform by a Carnegie Mellon University professor.',
    projectName: 'Math-Wizard.webp',
    category: ['Web', 'EdTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'Stackswap',
    featuredImageSrc: 'Stackswap.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/stackswap.svg').default,
    projectDescription:
      'Stackswap is a decentralized exchange (DEX) that issues and exchanges tokens on the Bitcoin Network through the Stacks blockchain.',
    projectName: 'Math-Wizard',
    category: ['Web 3.0'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'Lonely planet Globe Tripper',
    featuredImageSrc: 'bbc.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/lonely-planet-globe-tripper.svg')
      .default,
    projectDescription:
      "Tintash developed a travel-themed quiz game for BBC Games to promote the  popular 'Lonely Planet' brand.",
    projectName: 'Lonely-planet-Globe-Tripper.webp',
    category: ['Games', 'EdTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'EdTech LMS',
    featuredImageSrc: 'Edtech-LMS.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/careem.svg').default,
    projectDescription:
      'A career education platform and the world’s leading provider of vocational education. Tintash provided serverless computing services and helped integrate third party LMS and SIS into their existing system.',
    projectName: 'EdTech LMS',
    category: ['Web', 'EdTech'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
  {
    id: 'common-sense',
    featuredImageSrc: 'common-sense.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/common-sense.svg').default,
    projectDescription:
      'On cell phones and tablets, choose age appropriate content for your child through independent reviews, age ratings, and other information about all types of media.',
    projectName: 'common-sense',
    category: ['EdTech', 'Apps'],
    categories: {
      solutions: [
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
      ],
    },
    isLogoVisible: true,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },

  {
    id: 'Sports Ecommerce',
    featuredImageSrc: 'sports-ecommerce.webp',
    logoSrc: require('../../assets/images/portfolio/projects/logos/curated-logo.webp').default,
    projectDescription:
      'An e-commerce app dealing in winter sports gear, and humanizing the online shopping experience by connecting customers with passionate experts.',
    projectName: 'Curated',
    category: ['Web'],
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'User Interface', label: 'User Interface'},
        {value: 'Project Management', label: 'Project Management'},
      ],
    },
    isLogoVisible: false,
    isFeaturedCaseStudy: false,
    caseStudyUrl: '',
  },
];

const categories = [
  'Apps',
  'Web',
  'Web 3.0',
  'AI',
  'AR/VR',
  'IoT',
  'Games',
  'EdTech',
  'HealthTech',
];

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryChange = category => {
    setSelectedCategory(category);
  };

  let filteredProjects = [];

  if (selectedCategory) {
    if (selectedCategory === 'Other') {
      filteredProjects = PROJECTS.filter(project => !project.category.length);
    } else {
      filteredProjects = PROJECTS.filter(project => project.category.includes(selectedCategory));
    }
  } else {
    filteredProjects = PROJECTS;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio | Tintash</title>
      </Helmet>
      <div className="portfolio-container">
        <HeadingBanner
          heading={
            <div>
              We have expertise across a
              <strong style={{color: '#1AA5F8'}}> range of verticals and services.</strong>{' '}
            </div>
          }
          description={
            <div>
              Since 2008, we've helped companies build products, and delivered more than 500
              projects to start-ups, scale ups and large companies.
            </div>
          }
          imageSrc={require('../../assets/images/company/topbanner-bg-2.webp').default}
        />
        {/* Projects section */}
        <section style={{fontFamily: 'Lato, sans-serif'}} className="py-5">
          <div className="container">
            <ProjectFilter
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={handleCategoryChange}
            />
            <DropDownProjectFilter
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={handleCategoryChange}
            />
          </div>
          <div className="py-5">
            <ProjectGrid projects={filteredProjects} />
          </div>
        </section>
      </div>
    </>
  );
};

export default Portfolio;
